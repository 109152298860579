<template>
  <div class="grid">
    <div class="title-top">
      <h1>Acesso Negado</h1>
      <p>
        Você não tem permissão para acessar esta área.
        <br />
        Por favor, entre em contato com o administrador da sua conta.
      </p>
    </div>
    <img class="img " src="@assets/images/backgrounds/not-found.png" />
    <div class="text d-flex align-center">
      <v-row no-gutters>
        <v-col cols="12" class="mb-9">
          <span>
            Entre em contato conosco:
          </span>
          <a link="mailto:atendimento@simplificador.com.br">
            atendimento@simplificador.com.br
          </a>
        </v-col>
        <v-col cols="12">
          <v-btn class="mb-3 btn" small @click="goBack" color="success"
            >Voltar a página anterior</v-btn
          >
        </v-col>
        <v-col cols="12">
          <v-btn class="btn" small @click="navigateTo('/')" color="success"
            >Ir para a home</v-btn
          >
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'page-not-found',
  methods: {
    goBack() {
      window.history.back()
    },
    navigateTo(path) {
      this.$router.push({ path: path }).catch(() => {})
    },
  },
}
</script>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 10rem 20rem;
  justify-content: center;
}

.img {
  grid-row: 1/1;
  grid-column: 1 / span 1;
  width: calc(100% - 1px);
}
.btn {
  margin-right: 20px;
}
.text {
  grid-row: 2/2;
  grid-column: 1 / span 1;
}
.title-top {
  grid-row: 1/1;
  grid-column: 1 / span 1;
  z-index: 2;
  width: calc(100% - 10px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media only screen and (max-width: 600px) {
  .grid {
    grid-template-rows: 10rem 10rem;
  }
  .img {
    grid-row: 2/2;
    grid-column: 1 / span 1;
  }
}
</style>
